import { helpers } from "vuelidate/lib/validators";
import { isEmpty } from "lodash";
import { isBefore, isValid, parseISO, isDate, isEqual } from "date-fns";
import moment from "moment";
import Store from "../store/index";
import ObjectUtils from "./ObjectDiff";
import { isPlainObject } from "lodash";
import { upperCaseInHtmlTree } from "./upperCaseHtmlText";
import {
  CaseStatusEnum,
  DateFormats,
  LabNumberingMethodsEnum,
  MacroDateTimeEnum,
  SpecimenNumbersEnum,
  UserTypesEnum,
  MRNMatchingEnum,
  CaseEditTypeEnum
} from "./enums";
import getOS from "./getOs";
const uuid = () => {
  let crypto = window.crypto || window.msCrypto;
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

export function filterAccessionNumber(columnName, numberingType, exact) {
  return (value, operator) => {
    value = calculateAccessionNumbering(value, numberingType);
    return operator ? [columnName, operator, value] : [columnName, exact ? "=" : "contains", value];
  };
}

export function scrollToElement(element) {
  const getScrollParent = node => {
    if (node === null) {
      return null;
    }

    if (node.scrollHeight > node.clientHeight) {
      return node;
    } else {
      return getScrollParent(node.parentNode);
    }
  };
  const scrollParent = getScrollParent(element);
  if (scrollParent) {
    scrollParent.scrollTo({
      left: 0,
      top: element.offsetTop - scrollParent.offsetTop,
      behavior: "smooth"
    });
  }
}
export const booleanLookup = {
  dataSource: [
    { id: true, displayName: "Yes" },
    { id: false, displayName: "No" }
  ],
  valueExpr: "id",
  displayExpr: "displayName"
};

const innerTextMatcher = new RegExp(/(?:<\w>+)(?<word>.+)(?:<\/\w>)/im);
function parseTextInsideHTML(entry) {
  const matches = entry.match(innerTextMatcher);
  if (matches) {
    for (let i = 1; i < matches.length; i++) {
      const match = matches[i];
      entry = entry.replace(match, match.toUpperCase());
    }
    return entry;
  }
  return entry.toUpperCase();
}
export function shortenAccessionNumber(accessionNumber = "") {
  const matcher = /([a-z]+)(?:[0-9]{2})(\d{2})(?:-)([0-9]+)/im;
  if (matcher.test(accessionNumber)) {
    accessionNumber = accessionNumber.replace(matcher, function (match, g1, g2, g3) {
      return g1 + g2 + "-" + parseInt(g3, 10);
    });
  }
  return accessionNumber;
}
export function calculateAccessionNumbering(
  value,
  numberingType = LabNumberingMethodsEnum.Departamental
) {
  value = value?.toString();
  if (/^(?<prefix>[a-z]*)(?<caseNumber>(?<year>[0-9]{2})$)/i.test(value)) {
    const match = value.match(/^(?<prefix>[a-z]*)(?<caseNumber>(?<year>[0-9]{2})$)/i);
    let { year, prefix } = match.groups;
    const today = new Date();
    if (year.length !== 4) {
      //Creates a 4 digit year
      year = parseInt(today.getFullYear().toString().slice(0, 2) + year);
      //If the year is greater than today's year
      //We try the previous century.
      if (year > today.getFullYear()) {
        year = parseInt(
          (parseInt(today.getFullYear().toString().slice(0, 2)) - 1).toString() + match.groups.year
        );
      }
      return prefix + year;
    }
  }
  let includesDash = /-/i.test(value);
  let accessionNumberRegex =
    /^(?<prefix>[a-z]*)(?<caseNumber>(?<year>[0-9]{2})-?(?<numberSequence>[0-9]{1,7}))/i;
  let match = value.match(accessionNumberRegex);
  if (match) {
    if (value.length > 10) {
      match = value.match(
        /^(?<prefix>[a-z]*)(?<caseNumber>(?<year>[0-9]{2,4})-?(?<numberSequence>[0-9]{1,7}))/i
      );
    }
    let { year, prefix, numberSequence } = match.groups;
    if (includesDash) {
      const indexOfDash = value.indexOf("-");
      year = value.slice(0, indexOfDash).replace(/[a-z]/gi, "");
      if (value.slice(indexOfDash + 1)) {
        numberSequence = value.slice(indexOfDash + 1);
      }
    }

    const today = new Date();
    if (year.length !== 4) {
      //Creates a 4 digit year
      year = parseInt(today.getFullYear().toString().slice(0, 2) + year);
      //If the year is greater than today's year
      //We try the previous century.
      if (year > today.getFullYear()) {
        year = parseInt(
          (parseInt(today.getFullYear().toString().slice(0, 2)) - 1).toString() + match.groups.year
        );
      }
    }
    if (numberSequence.length < 7) {
      numberSequence = numberSequence.padStart(7, "0");
    }
    if (numberingType === LabNumberingMethodsEnum.Pathology) {
      //Pathology Numbering
      return year + "-" + numberSequence;
    } else {
      //Departamental Numbering
      return prefix + year + "-" + numberSequence;
    }
  }
  return value;
}

export function calculateAccessionNumberingWithoutPrevCentury(value) {
  if (!value) {
    return value;
  }
  if (typeof value !== "string") {
    value = value?.toString();
  }
  let includesDash = /-/i.test(value);
  let accessionNumberRegex = /^(?<caseNumber>(?<year>[0-9]{2})-?(?<numberSequence>[0-9]{1,7}))/i;
  let match = value.match(accessionNumberRegex);
  if (match) {
    if (value.length > 10) {
      match = value.match(/^(?<caseNumber>(?<year>[0-9]{2,4})-?(?<numberSequence>[0-9]{1,7}))/i);
    }
    let { year, numberSequence } = match.groups;
    if (includesDash) {
      const indexOfDash = value.indexOf("-");
      year = value.slice(0, indexOfDash).replace(/[a-z]/gi, "");
      if (value.slice(indexOfDash + 1)) {
        numberSequence = value.slice(indexOfDash + 1);
      }
    }
    const today = new Date();
    if (year.length !== 4) {
      //Creates a 4 digit year
      year = parseInt(today.getFullYear().toString().slice(0, 2) + year);
    }
    if (numberSequence.length < 7) {
      numberSequence = numberSequence.padStart(7, "0");
    }
    //Pathology Numbering
    return year + "-" + numberSequence;
  }
  return value;
}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  let countryCode = "";
  if (cleaned.length > 10) {
    const countryCodeLength = cleaned.length - 10;
    countryCode = cleaned.slice(0, countryCodeLength);
    cleaned = cleaned.slice(countryCodeLength);
  }
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return countryCode + "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return cleaned;
}
const validatorMsgMapBase = {
  required: "This field is required.",
  email: "Must be a valid Email",
  hasUppercase: "Must have an uppercase letter",
  hasDigit: "Must have a digit",
  hasNonAlphanumeric: "Must have non-alphanumeric character",
  alphaNum: "Must be alphanumeric only",
  alpha: "Must only contain alphabet characters.",
  numeric: "Must only contain numbers.",
  MaxDiffCollectedAndReceived: "Must be within the proper range of dates.",
  maxFutureDaysForReceived: "Must not exceed maximum number of future days.",
  maxValue: "Exceeds maximum value.",
  maxLength: "Exceeds max length",
  minLength: "Must meet minimum length.",
  maxDateOfBirth: "Date may not be in the future.",
  collectedMaxValue: "Must not be after received date.",
  afterEffective: "Must be after effective date.",
  noFutureDate: "Date must not be in the future.",
  noPastDates: "Date must not be in the past.",
  onePrimary: "Must have at least one primary provider. ",
  notPlaceholder: "This field is required.",
  caseNumberValidator: "Case number is not available.",
  validCaseNumber: "Must only contain numbers.",
  DobBeforeCollected: "Date of Birth must be before Collected On date.",
  CollectedAfterDob: "Collected On date may not be before Date of Birth.",
  uniqueSearchName: "Search name is already in use.",
  onlyLetters: "No spaces or symbols allowed.",
  collectedBeforeReceived: "Received date must be on or after Collected date.",
  blockMustBeNumbers: "Block ID must be a number.",
  blockMustBeLetters: "Block ID must be letters.",
  onlyNumbersOrLetters: "Value must be only numbers or letters.",
  hasPrefix: "Case number must have a prefix.",
  inSamePrefixGroup: "Cases must be in same prefix group.",
  prefixCodeExists: "A prefix with this code already exists.",
  sameUser: "Cannot set proxy as same user.",
  isProxyUnique: "A proxy configuration already exists for this pathologist and user.",
  validOrderNumber: "Must only contain numbers, letters, or hyphens.",
  macroHasPeriod: "May not contain a period."
};
function noFutureDate(value) {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  if (value != undefined && value != null) {
    if (!isDate(value)) {
      value = parseISO(value);
    }
    if (!moment(value).isValid()) {
      return true;
    }
    return moment(value).isSameOrBefore(moment(tomorrow));
  }
  return true;
}
function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

function maxFutureDaysForReceived(
  value,
  MaxFutureDaysForReceivedDate = 30,
  accessionDate = new Date()
) {
  if (MaxFutureDaysForReceivedDate != null) {
    const maxDay = new Date();
    const receivedOn = new Date(value);
    maxDay.setDate(accessionDate.getDate() + Number(MaxFutureDaysForReceivedDate));
    return receivedOn.getTime() < maxDay.getTime();
  }
  return true;
}
function MaxDiffCollectedAndReceived(value, MaxDiffCollectedAndReceivedDates = 30, receivedOn) {
  if (value) {
    const min = moment(receivedOn, ["yyyy-MM-DD"])
      .subtract(MaxDiffCollectedAndReceivedDates + 1, "days")
      .format("yyyy-MM-DD");
    return !moment(value).isBefore(min, "days");
  }
  return true;
}

function afterEffective(value, vm) {
  if (value != null && value != undefined) {
    return moment(value).isSameOrAfter(vm.effectiveOn);
  }
  return true;
}

function effectiveMinValue(value) {
  const today = new Date();
  return moment(value).isSameOrBefore(today);
}

function isIE() {
  return (
    navigator.userAgent.indexOf("MSIE") !== -1 || navigator.appVersion.indexOf("Trident/") > -1
  );
}

export function getStatusIcon(data = {}) {
  const caseStatus = data.caseStatus || data.status;
  const props = {
    icon: "circle",
    tooltip: "",
    class: ""
  };
  const signedOn = data?.signedOn || data?.lastSignedOn;
  if (
    [CaseStatusEnum.Reported, CaseStatusEnum.ReReleased].includes(caseStatus) &&
    !pastLabDelay(signedOn)
  ) {
    props.class = "text-danger";
    props.tooltip = "Results Pending";
  } else if (caseStatus !== CaseStatusEnum.ReReleased && caseStatus !== CaseStatusEnum.Reported) {
    props.class = "text-danger";
    props.tooltip = "Results Pending";
  } else if (caseStatus === CaseStatusEnum.ReReleased) {
    props.class += " text-warning ";
  } else {
    props.class = "d-none";
  }
  return props;
}
export function formatDatetimeCell(data) {
  var date = new Date(data);
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const updatedDate = new Date(date.getTime() - userTimezoneOffset);
  return updatedDate ?? "";
}

export function filterCellUTC(dataField) {
  const createFilter = (filterValue, operation) => {
    if (operation === "between" && Array.isArray(filterValue)) {
      return [createFilter(filterValue[0], ">="), "and", createFilter(filterValue[1], "<=")];
    }
    if (filterValue instanceof Date) {
      const filterInUTC = moment(filterValue).utc().format("YYYY-MM-DDTHH:mm:ss");
      return [dataField, operation ?? "=", filterInUTC];
    }
    return [dataField, operation ?? "=", filterValue];
  };
  return createFilter;
}
export function dateRangeFilter(
  effective = "effectiveOn",
  expiry = "expiryOn",
  canEffectiveBeNull
) {
  const format = "yyyy-MM-DD";
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  const formattedTomorrow = moment(tomorrow).format(format);
  const formattedToday = moment(today).format(format);
  const effectiveFilter = canEffectiveBeNull
    ? [[effective, "<=", formattedTomorrow], "or", ["effectiveOn", null]]
    : [effective, "<=", formattedTomorrow];
  return [effectiveFilter, "and", [[expiry, ">=", formattedToday], "or", [expiry, "=", null]]];
}
export function addLoadFilter(loadOptions) {
  if (Array.isArray(loadOptions.filter)) {
    loadOptions.filter = [
      [...loadOptions.filter],
      "and",
      dateRangeFilter("effectiveOn", "expiryOn")
    ];
  } else {
    loadOptions.filter = [dateRangeFilter("effectiveOn", "expiryOn")];
  }
}
const SSNregex = /(^(?!9\d{2}|000|666)\d{3})[-]*((?!0{2})\d{2})[-]*((?!0{4})\d{4})/;
const SSNValidator = value => {
  if (typeof value === undefined || value === null || value === "") {
    return true;
  }
  return SSNregex.test(value);
};
const telephone = helpers.regex("tel", /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/);
const DateValidator = helpers.regex("Date", /[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}/);
const zipCode = function (value) {
  if (value && value != undefined) {
    return /^([a-zA-Z\d@#$^%\-~!\s]*)*$/.test(value);
  }
  return true;
};

export function syntaxHighlight(json) {
  json = json.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    function (match) {
      var cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
}

const collectedBeforeReceivedOn = (value, vm) => {
  let receivedOn = vm.receivedOn;
  if (typeof value === "string") {
    value = parseISO(value);
  }
  if (typeof receivedOn === "string") {
    receivedOn = parseISO(receivedOn);
  }
  if (typeof value === "string" && isValid(parseISO(value))) {
    value = parseISO(value);
  }

  return isEqual(value, receivedOn) || isBefore(value, receivedOn);
};

/**
 *  Grab all text nodes to HTML.
 * @param {string} html
 * @returns
 */
function getTextFromHtml(html, handleLineBreaks) {
  if (html) {
    if (handleLineBreaks) {
      html = html.replaceAll("<br>", " ").replaceAll(/(<div> +?<\/div>)+/gi, " ");
    }
    return new DOMParser().parseFromString(html, "text/html").documentElement.textContent;
  }
  return "";
}

export function sanitizeHTML(payload, labSettings = {}) {
  const {
    ForceUpperCaseCaseNote,
    ForceUpperCaseClinical,
    ForceUpperCaseDiagnosis,
    ForceUpperCaseGross,
    ForceUpperCaseMicroscopic,
    ForceUpperCaseSpecimenNote
  } = labSettings;
  const upperCaseMap = {
    clinical: ForceUpperCaseClinical,
    gross: ForceUpperCaseGross,
    diagnosis: ForceUpperCaseDiagnosis,
    microscopic: ForceUpperCaseMicroscopic,
    notes: ForceUpperCaseSpecimenNote,
    caseNotes: ForceUpperCaseCaseNote
  };
  const htmlEmptyCleaner = /<div><\/div>|(<div>(<br\/?>)*<\/div>$)/gim;
  const quillCursor = /<span class=\W*ql-cursor\W*>[^<]*<\/span>/gimu;
  [
    "clinical",
    "gross",
    "diagnosis",
    "microscopic",
    "notes",
    "caseNotes",
    "frozenText",
    "synopticText",
    "generalText"
  ].forEach(field => {
    if (typeof payload[field] === "string") {
      // Clears zero-space unicode characters
      payload[field] = payload[field].replaceAll(/[\u200B-\u200D\uFEFF]/g, "");
      if (getTextFromHtml(payload[field])?.length === 0) {
        payload[field] = null;
      } else {
        if (upperCaseMap[field]) {
          payload[field] = upperCaseInHtmlTree(payload[field]);
        }
        // Replace all line break characters with br tags
        payload[field] = payload[field].replaceAll("\n", "<br>");
        // This fixes font names with multiple words that otherwise would not display correctly on path reports
        if (payload[field]) {
          const multiWordFonts = payload[field].match(
            /(?<fontWithQuotes>font-family: &quot;(?<fontName>\w+( \w+)+)&quot;)/gi
          );
          if (multiWordFonts?.length) {
            for (const fontWithQuotes of multiWordFonts) {
              const {
                groups: { fontName }
              } = /(?<fontWithQuotes>font-family: &quot;(?<fontName>\w+( \w+)+)&quot;)/i.exec(
                fontWithQuotes
              );
              payload[field] = payload[field].replaceAll(
                fontWithQuotes,
                "font-family: " + fontName
              );
            }
          }
          const rgbRegex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/g;

          if (rgbRegex.test(payload[field])) {
            const rgbColors = payload[field].match(rgbRegex);
            // Convert RGB to hex for each color
            rgbColors.forEach(rgbColor => {
              const hexColor = rgbToHex(rgbColor);
              payload[field] = payload[field].replace(rgbColor, hexColor);
            });
          }
        }
        // Keeps multiple spaces. These were being reduced to 1 space by SSRS
        if (
          payload[field] &&
          /(?:(?<!\s|(&nbsp;)))(?<spaces>(\s| |(&nbsp;)){2,})(?:(?!\s|(&nbsp;)))(?:(?<=\s|(&nbsp;)))/.test(
            payload[field]
          )
        ) {
          const matches = [
            ...payload[field].matchAll(
              /(?:(?<!\s|(&nbsp;)))(?<spaces>(\s| |(&nbsp;)){2,})(?:(?!\s|(&nbsp;)))(?:(?<=\s|(&nbsp;)))/gi
            )
          ].map(e => e?.groups?.spaces);
          for (const match of matches) {
            const matchLength = match.replaceAll("&nbsp;", " ").length;
            payload[field] = payload[field].replace(match, "&nbsp;".repeat(matchLength));
          }
        }
        // Replace tab characters with 8 non-breaking spaces
        if (payload[field] && payload[field].includes("\t")) {
          payload[field] = payload[field].replaceAll("\t", "&nbsp;".repeat(8));
        }
        // Replace spaces after line breaks with non-breaking spaces
        if (payload[field] && /> /.test(payload[field])) {
          payload[field] = payload[field].replaceAll(/> /g, ">&nbsp;");
        }
        if (quillCursor.test(payload[field])) {
          // Remove span added by Quill that was adding question marks on reports
          payload[field] = payload[field].replaceAll(quillCursor, "");
        }
        if (htmlEmptyCleaner.test(payload[field])) {
          //Cleans any empty div tags and removes trailing linebreaks.
          payload[field] = payload[field].replace(htmlEmptyCleaner, "");
        }
        // Clear out spell checker underlining
        payload[field] = clearSpellCheckHtml(payload[field]);
      }
    }
  });
}

/**
 *
 */
/**
 *  Source: https://stackoverflow.com/questions/11089399/count-with-a-b-c-d-instead-of-0-1-2-3-with-javascript
 * @param {number} num
 * @returns
 */
export function toLetters(num = 0) {
  const mod = num % 26;
  let pow = (num / 26) | 0;
  const out = mod ? String.fromCharCode(64 + mod) : (--pow, "Z");
  return pow ? toLetters(pow) + out : out;
}

export function fromLetters(str = "") {
  var out = 0,
    len = str.length,
    pos = len;
  while ((pos -= 1) > -1) {
    out += (str.charCodeAt(pos) - 64) * Math.pow(26, len - 1 - pos);
  }
  return out;
}
/**
 *  Generate specimen order using the current specimens and target numbering type.
 * @param {Array} specimens // Array of current specimens on the case.
 * @param {number} numberingType // Found in labSettings
 * @returns
 */
function getNextSpecimenOrder(specimens, numberingType = 1) {
  const num = specimens.length + 1;
  if (numberingType === 2) {
    return toLetters(num);
  } else {
    return num;
  }
}

export function createQueryArray(arr, prop) {
  return arr.length
    ? arr.map((e, idx) => (idx === 0 ? `${prop}=${e}` : `&${prop}=${e}`)).join("")
    : "";
}

export function createPatientMatchPayload(caseDetails) {
  const { patientLastName, patientFirstName, patientDOB, patientMRN, patientSSN, labPrefix } =
    caseDetails;
  let payload = {};
  const hasValidDOB = patientDOB && moment(patientDOB).isBefore(moment(new Date()));
  const parsedDOB = hasValidDOB ? moment(patientDOB, DateFormats).format("MM/DD/YYYY") : null;
  const hasValidSSN = patientSSN && SSNValidator(patientSSN);
  if (patientMRN && patientMRN.length > 4) {
    const matchMrnAlone =
      Store.state.labSettings.PatientMatchingonMRNAloneOption !== MRNMatchingEnum.Never;
    if (matchMrnAlone) {
      payload.mrn = patientMRN;
    }
    if (hasValidDOB) {
      if (!matchMrnAlone) {
        payload.mrn = patientMRN;
      }
      payload.dob = parsedDOB;
    }
  }
  if (hasValidSSN) {
    payload.ssn = patientSSN;
    if (hasValidDOB) {
      payload.dob = parsedDOB;
    }
  }
  if (patientFirstName && patientLastName && hasValidDOB) {
    payload.firstName = patientFirstName;
    payload.lastName = patientLastName;
    payload.dob = parsedDOB;
  }
  if (Object.keys(payload).length) {
    payload.prefixId = labPrefix;
    return payload;
  }
  return false;
}

export const caseCreationMethodEnum = [
  {
    displayName: "Manual",
    id: 1
  },
  {
    displayName: "HL7",
    id: 2
  },
  {
    id: 3,
    displayName: "Barcode"
  },
  {
    id: 4,
    displayName: "Converted"
  }
];

export function getLastInsertOperation(ops) {
  const lastOperation = ops[ops.length - 1];
  const isLastOperationInsert = "insert" in lastOperation;

  if (isLastOperationInsert) {
    return lastOperation;
  }

  const isLastOperationDelete = "delete" in lastOperation;

  if (isLastOperationDelete && ops.length >= 2) {
    const penultOperation = ops[ops.length - 2];
    const isPenultOperationInsert = "insert" in penultOperation;
    const isSelectionReplacing = isLastOperationDelete && isPenultOperationInsert;

    if (isSelectionReplacing) {
      return penultOperation;
    }
  }

  return null;
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 *
 * @param {string} value a string
 * @returns
 */
export function createRegExFromMatchers(value) {
  const orderMatchParser = /(?<matcher>([{](.*)(?<orderNumber>[x0-9]*).*[}]))/i;
  const createRegexString = value.match(orderMatchParser);
  if (!createRegexString) {
    window.notify(`Invalid input no matchers found.`, "error");
    return;
  }
  const matchers = new Set(
    value.split("|").map(matcherString => {
      matcherString = RegExp.escape(matcherString);
      const { matcher } = matcherString.match(orderMatchParser).groups;
      return matcher.replace(/[{}\\]/g, "").replace(/([x]+)/, "(?<orderNumber>[a-z0-9]+)");
    })
  );
  return Array.from(matchers).map(matcher => new RegExp(matcher, "i"));
}
/**
 *
 * @param {Object} specimen  //A Target specimen to build the HTML for.
 * @param {Array} protocols // Needs the enum for protocols acquired from API.
 * @param {Array} bodyParts // Needs the enum for body parts acquired from API
 * @returns
 */
export function buildTitle(specimen, protocols, bodyParts) {
  if (!specimen) {
    return "";
  }
  const mappedProtocol = protocols.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.id]: item.displayName
      }),
    {}
  );
  const mappedBodyPart = bodyParts.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.id]: item.displayName
      }),
    {}
  );
  let specimenOrder = specimen.specimenOrder || "";
  let protocol = mappedProtocol[specimen.protocolId] || "";
  let bodyPart = mappedBodyPart[specimen.bodyPartId]
    ? mappedBodyPart[specimen.bodyPartId] + ","
    : "";
  let site = specimen.site ? specimen.site + "," : "";
  let diagnosisHTML = createDiagnosisSummary(specimen);

  return `<div class="d-flex align-items-baseline">
 <span class="m-0">
  Specimen: ${specimenOrder}, ${site} ${bodyPart} ${protocol}${
    specimen?.macroIsModified ? ' <span class="text-danger">(macro modified)</span>' : ""
  }
 </span>
    ${diagnosisHTML}
  </div>`;
}

export function createDiagnosisSummary(specimen) {
  if (specimen.mostSevereDiagnosisSummary) {
    return `
          <span class="bg-${specimen.mostSevereDiagnosisSummary.backgroundColor} priority mx-2 p-1">${specimen.mostSevereDiagnosisSummary.displayText}</span>
      `;
  }
  return "";
}

export function createLogComment(original = {}, updated = {}) {
  const originalClone = Object.keys(updated).reduce((acc, key) => {
    acc[key] = original[key];
    return acc;
  }, {});
  const diff = ObjectUtils.diff(originalClone, updated);
  let updateComment = {};
  const hasAdded = !isEmpty(diff.added);
  const hasRemoved = !isEmpty(diff.removed);
  const hasUpdated = !isEmpty(diff.updated);
  if (hasAdded) {
    Object.keys(diff.added).forEach(key => {
      const targetChange = diff.added[key];
      updateComment[key] = enumDisplayName(key, targetChange.oldValue);
    });
  }
  if (hasRemoved) {
    Object.keys(diff.removed).forEach(key => {
      const targetChange = diff.removed[key];
      updateComment[key] = enumDisplayName(key, targetChange.oldValue);
    });
  }
  if (hasUpdated) {
    Object.keys(diff.updated).forEach(key => {
      const targetChange = diff.updated[key];
      if (Array.isArray(targetChange.oldValue) && Array.isArray(targetChange.newValue)) {
        const innerDiff = ObjectUtils.diff(targetChange.oldValue, targetChange.newValue);
        const innerUpdated =
          !isEmpty(innerDiff.updated) || !isEmpty(innerDiff.added) || !isEmpty(innerDiff.removed);
        if (innerUpdated) {
          if (targetChange.oldValue.length === targetChange.newValue.length) {
            const changes = targetChange.oldValue
              .map((item, idx) => {
                const displayName = enumDisplayName(key, item);
                if (targetChange.newValue) {
                  const newValueItem = targetChange.newValue[idx];
                  if (newValueItem?.id === item?.id) {
                    if (JSON.stringify(newValueItem) !== JSON.stringify(item)) {
                      const currentChanges = JSON.parse(
                        createLogComment(item, newValueItem) || "{}"
                      );
                      if (!isEmpty(currentChanges)) {
                        return currentChanges;
                      }
                    }
                    return;
                  }
                  const newItemDisplay = enumDisplayName(key, targetChange.newValue[idx]);
                  if (newItemDisplay !== displayName) {
                    return displayName;
                  }
                }
                if (displayName) {
                  return displayName;
                }
                return item;
              })
              .filter(e => e);
            if (changes?.length) {
              updateComment[key] = changes;
            }
          } else {
            updateComment[key] = targetChange.oldValue.map(item => {
              const displayName = enumDisplayName(key, item);
              if (displayName) {
                return displayName;
              }
              return item;
            });
          }
        }
      } else if (isPlainObject(targetChange.oldValue) && isPlainObject(targetChange.newValue)) {
        if (JSON.stringify(targetChange.oldValue) !== JSON.stringify(targetChange.newValue)) {
          updateComment[key] = targetChange.oldValue;
        }
      } else {
        const isDate = parseISO(targetChange.oldValue);
        if (isValid(isDate)) {
          if (moment(original[key]).isSame(moment(updated[key]), "day")) {
            return;
          } else {
            const oldDateValue = moment(targetChange.oldValue);
            updateComment[key] = oldDateValue.format("MM-DD-YYYY");
          }
        }
        if (
          targetChange.oldValue ||
          targetChange.newValue ||
          typeof targetChange.oldValue === "boolean"
        ) {
          updateComment[key] = targetChange.oldValue;
        }
      }
    });
  }
  if (!isEmpty(updateComment)) {
    return JSON.stringify(updateComment, null, 2);
  }
  return "";
}

function enumDisplayName(key, item) {
  let displayName;
  switch (key) {
    case "pathologists":
      displayName = item?.displayName ?? item.firstName + " " + item.lastName;
      break;
    case "contacts":
      displayName = item?.contact?.displayName;
      break;
    case "email":
      displayName = item.email;
      break;
    case "phoneNumbers":
      displayName = item.phoneNumber;
      break;
    case "specimenRates":
      displayName = `${item.num} ${item.description ? "---" + item.description : ""}`;
      break;
    case "roles":
      displayName = item.name;
      break;
    case "users":
      displayName = item.userName;
      break;
    case "procedures":
      displayName = `${item.code || ""}-${item.description || ""}`;
      break;
    case "icdWords":
      displayName = item.value;
      break;
    default:
      displayName = item?.displayName || item.id;
      break;
  }
  if (item.isPrimary) {
    displayName += " (Primary)";
  }
  return displayName;
}

export function jwtDecode(t) {
  let token = {};
  token.raw = t;
  token.header = JSON.parse(window.atob(t.split(".")[0]));
  token.payload = JSON.parse(window.atob(t.split(".")[1]));
  return token;
}
export function getBrowser() {
  const { userAgent } = navigator;
  let match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  let temp;

  if (/trident/i.test(match[1])) {
    temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];

    return `IE ${temp[1] || ""}`;
  }

  if (match[1] === "Chrome") {
    temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/);

    if (temp !== null) {
      return temp.slice(1).join(" ").replace("OPR", "Opera");
    }

    temp = userAgent.match(/\b(Edg)\/(\d+)/);

    if (temp !== null) {
      return temp.slice(1).join(" ").replace("Edg", "Edge (Chromium)");
    }
  }
  match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, "-?"];
  temp = userAgent.match(/version\/(\d+)/i);
  if (temp !== null) {
    match.splice(1, 1, temp[1]);
  }
  return match.join(" ");
}
export function minutesCalculator(minutes = 15) {
  return minutes * 1000 * 60;
}
export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}
export function createLogItem(caseDetails = {}, item, page = window.location.href) {
  const { currentUser, systemInformation, currentLab } = Store.state;
  const {
    caseNumber,
    patientFirstName,
    patientLastName,
    patientAccountNumber,
    orderNumber,
    patientDOB,
    patientSSN,
    patientMRN,
    status
  } = caseDetails;
  return {
    item,
    labId: currentLab ?? currentUser?.labId,
    accessionNumber: caseNumber,
    name: `${patientFirstName || ""} ${patientLastName || ""}`,
    orderNumber,
    dob: patientDOB ?? null,
    accountNumber: patientAccountNumber ?? caseDetails.acctNumber,
    ssn: patientSSN,
    mrn: patientMRN,
    user: currentUser?.id,
    ipAddress: systemInformation?.remoteIpAddress,
    pageOrGridViewed: page,
    internetBrowser: getBrowser(),
    comments: "",
    activityDate: new Date().toISOString(),
    caseStatusId: status
  };
}

export function formatSSN(value) {
  value = value.replace(/[a-zA-z\D\s]+/, "");
  const matches = value.match(/([\d]{3})-*([\d]{2})-*([\d]{4})/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }
  return value;
}

export function utcToLocal(value) {
  return moment.utc(value).local().format("MM/DD/YYYY, hh:mm a");
}

export function oneYearAgo() {
  return new Date(new Date().setFullYear(new Date().getFullYear() - 1));
}

export function daysAgo(days) {
  return new Date(moment().subtract(days, "days"));
}

export function daysBefore(date, days) {
  return new Date(moment(date).subtract(days, "days"));
}

export function daysAfter(date, days) {
  return new Date(moment(date).add(days, "days"));
}

export function altKey(key) {
  const os = getOS();
  if (os.includes("Mac")) {
    return ["ctrl", key];
  }
  return ["alt", key];
}

export function removeExtraDivs(text) {
  if (/<div>(<br>)?<\/div>$/.test(text)) {
    text = text.replace(/(<div>(<br>)?<\/div>)+$/, "");
  }
  return text;
}

export function isValidBlockNum(value, isCaseOrder, specimenNumbering) {
  const numbering = Store.state.labSettings.SpecimenNumberingTypes;
  if (isCaseOrder || numbering !== specimenNumbering) {
    return true;
  }
  if (numbering === SpecimenNumbersEnum.Numbers) {
    return /^[a-z]+$/i.test(value);
  } else if (numbering === SpecimenNumbersEnum.Letters) {
    const regex = new RegExp(`(^${isCaseOrder ? "[1-9][0-9]*" : "[0-9]+"}$)`, "i");
    return regex.test(value);
  } else {
    const regex = new RegExp(`(^[a-z]+$)|(^${isCaseOrder ? "[1-9][0-9]*" : "[0-9]+"}$)`, "i");
    return regex.test(value);
  }
}

export function fixProviderPayload(payload) {
  let newPayload = [];
  for (const provider of payload) {
    let newProvider = { ...provider };
    if (!provider?.contactId && provider?.id) {
      newProvider.contactId = provider.id;
      delete newProvider.id;
    }
    newPayload.push(newProvider);
  }
  return newPayload;
}

export function createLocalTzDateFilter(data, filterExpr, fieldName) {
  let date1;
  let date2;
  const day = 60 * 60 * 24 * 1000;
  if (Array.isArray(data)) {
    [date1, date2] = data;
  } else {
    date1 = data;
    date2 = date1;
  }
  date2 = new Date(date2.getTime() + day);
  switch (filterExpr) {
    case "<>":
      return [[fieldName, "<", date1.toISOString()], "or", [fieldName, ">=", date2.toISOString()]];
    case ">":
      return [fieldName, ">=", date2.toISOString()];
    case ">=":
      return [fieldName, ">=", date1.toISOString()];
    case "<=":
      return [fieldName, "<", date2.toISOString()];
    case "<":
      return [fieldName, "<", date1.toISOString()];
    default:
      return [[fieldName, ">=", date1.toISOString()], "and", [fieldName, "<", date2.toISOString()]];
  }
}

export function isSuperUser() {
  return Store.state.currentUser.userTypeId === UserTypesEnum.SuperUser;
}

export function addCommasToCount() {
  let pagerEl = document.querySelector(".dx-pager .dx-pages .dx-info");
  const originalText = pagerEl?.textContent;
  function addCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (originalText) {
    const regEx = new RegExp("(?<currentPage>\\d+) of (?<totalPages>\\d+) \\((?<totalCount>\\d+)");
    if (regEx.test(originalText)) {
      const {
        groups: { currentPage, totalPages, totalCount }
      } = regEx.exec(originalText);
      const newCurrentPage = addCommas(currentPage);
      const newTotalPages = addCommas(totalPages);
      const newTotalCount = addCommas(totalCount);

      const newText = originalText.replace(
        `${currentPage} of ${totalPages} (${totalCount}`,
        `${newCurrentPage} of ${newTotalPages} (${newTotalCount}`
      );
      pagerEl.textContent = newText;
    }
  }
}

export function removeTimeFromDatetime(datetime) {
  const regex = /^(?<date>[0-9-/]+)(?<time>T.+)?/.exec(datetime);
  return regex?.groups?.date || datetime;
}

export function isEffective({ expiryOn, effectiveOn }) {
  if (
    (expiryOn && moment(expiryOn) < moment()) ||
    (effectiveOn && moment(effectiveOn) > moment())
  ) {
    return false;
  }
  return true;
}

export function downloadBlob(file, fileName, fileType) {
  const url = window.URL.createObjectURL(file);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.download = `${fileName}.${fileType}`;
  a.click();
}

export function checkIfProxy(pathologistId) {
  if (!Store.state.doctorProxies?.length || !pathologistId) {
    return false;
  }
  const hasProxy = Store.state.doctorProxies.find(e => e === pathologistId);
  return Boolean(hasProxy);
}

export function addTextToMacro(text, timeUsed = new Date().getTime()) {
  if (!text) {
    return text;
  }
  if (/&lt;/g.test(text)) {
    // If the macro has percent markup, change to brackets
    text = text.replaceAll("&lt;", "<").replaceAll("&gt;", ">");
  }
  const matches = text.match(/<@[TD][0-9]+[.\-/]?>/g);
  if (matches?.length) {
    for (const match of matches) {
      const {
        groups: { formatCode, separator }
      } = /<@(?<formatCode>[TD][0-9]+)(?<separator>[.\-/]?)>/g.exec(match);
      let formattedTimestamp = "";
      if (formatCode && MacroDateTimeEnum[formatCode]) {
        formattedTimestamp = moment(timeUsed).format(MacroDateTimeEnum[formatCode]);
      }
      if (separator) {
        formattedTimestamp = formattedTimestamp.replaceAll("/", separator);
      }
      if (formattedTimestamp) {
        text = text.replaceAll(match, formattedTimestamp);
      }
    }
  }
  if (/<!m>/i.test(text)) {
    const { measurement } = Store.state.accessionStore?.currentSpecimen;
    if (measurement) {
      text = text.replaceAll(/<!m>/gi, measurement);
    }
  }
  return text;
}

export function pastLabDelay(signedOn) {
  if (!signedOn) {
    return false;
  }
  if (Store.state.labSettings.ShowCasesToLabClientsDuringDistributionDelay) {
    return true;
  }
  const signedOut = moment.utc(signedOn).valueOf();
  const now = moment().valueOf();
  const delay = Store.state.labSettings.LabDistributionDelay * 60 * 1000 || 20 * 60 * 1000;
  return now > signedOut + delay;
}

export function calcultateNameFilter(searchQuery) {
  // Check if has comma followed by letters
  if (/, ?\w+$/.test(searchQuery)) {
    // If has comma, find last and first name and filter
    const {
      groups: { firstName, lastName }
    } = /(?<lastName>.+), ?(?<firstName>.+)?/.exec(searchQuery);
    return [
      ["patientLastName", "startswith", lastName.trim()],
      "and",
      ["patientFirstName", "startswith", firstName.trim()]
    ];
  } else {
    // If not, filter only by last name
    return [
      ["patientLastName", "startswith", searchQuery.replace(/\W+$/, "").trim()],
      "or",
      ["patientFirstName", "startswith", searchQuery.replace(/\W+$/, "").trim()]
    ];
  }
}

export function caseGridAccessionNumberFilter(numberingType) {
  return value => {
    value = calculateAccessionNumbering(value, numberingType);
    const caseNumberRegex =
      /(?<prefix>[A-Z]{1,6})?(?<year>((19)|(20))?[0-9]{2})?-?0{0,6}(?<caseNumSeq>[1-9][0-9]{0,6})?/i;
    if (!caseNumberRegex.test(value)) {
      return null;
    }
    const {
      groups: { prefix, year, caseNumSeq }
    } = caseNumberRegex.exec(value);
    const prefixFilter = prefix ? ["numberPrefix", "=", prefix.toUpperCase()] : null;
    const yearFilter = year ? ["numberYear", "=", parseInt(year)] : null;
    const caseNumberFilter = caseNumSeq ? ["numberSequence", "=", parseInt(caseNumSeq)] : null;
    let filter = [];
    if (prefixFilter) {
      filter = [prefixFilter];
    }
    if (yearFilter) {
      filter = prefixFilter ? [prefixFilter, "and", yearFilter] : [yearFilter];
    }
    if (caseNumberFilter) {
      filter = [...filter, "and", caseNumberFilter];
    }
    return filter;
  };
}

export function sortCaseInsensitive(array, displayExpr = "displayName") {
  return array.sort(function (a, b) {
    if (a[displayExpr].toLowerCase() < b[displayExpr].toLowerCase()) return -1;
    if (a[displayExpr].toLowerCase() > b[displayExpr].toLowerCase()) return 1;
    return 0;
  });
}

export function checkLeapDay(nv, ov) {
  // If there is no new value or old value, return new value.
  if (!ov || !nv) {
    return nv;
  }
  nv = moment(nv).format("YYYY-MM-DD");
  ov = moment(ov).format("YYYY-MM-DD");
  function isLeapYear(year) {
    const yearNum = parseInt(year);
    // Year must be divisible by 4
    if (yearNum % 4 > 0) {
      return false;
    }
    // If year is divisible by 100, must also be divisible by 400
    if (yearNum % 100 === 0 && yearNum % 400 > 0) {
      return false;
    }
    return true;
  }
  let prevMonth = ov.split("-")[1];
  let [year, month, day] = nv.split("-");
  // DxDateBox converts February 29 to March 1 or March 29 if the year is not a leap year
  if (month === "03" && ["29", "01"].includes(day) && prevMonth === "02" && !isLeapYear(year)) {
    for (let i = year; i > year - 5; i--) {
      if (isLeapYear(i)) {
        return `${i}-02-29`;
      }
    }
  }
  return nv;
}

export function useGeneralMacro(inputText, macroList) {
  const el = document.activeElement;
  const index = el.selectionEnd;
  if (index === undefined) {
    return inputText;
  }
  // Check if last character typed is space
  if (inputText[index - 1] === " ") {
    const macroSearchWithPeriod = Store.state.labSettings.MacroSearchWithPeriod;
    let macroPhrase = "";
    let breakChar = "";
    // Determine which character will stop looking for macro phrase
    const breakRegex = macroSearchWithPeriod ? /\./ : /[ \n]/i;
    if (macroList) {
      // Go through the letters of the input text backwards to build macro phrase
      for (let i = index; i > 0; i--) {
        const char = inputText[i - 1];
        // If the period lab setting is on and it finds a space before it finds a period, the function returns.
        if (macroSearchWithPeriod && i < index && char === " ") {
          return inputText;
        }
        if (breakRegex.test(char) && i !== index) {
          if (char !== ".") {
            breakChar = char;
          }
          break;
        }
        macroPhrase = char + macroPhrase;
      }
      if (macroPhrase.trim()) {
        const selectedGeneralMacro = macroList.find(
          e => e.macroName.toLowerCase() === macroPhrase.trim().toLowerCase()
        );
        if (selectedGeneralMacro) {
          const textToReplaceWith = getTextFromHtml(
            addTextToMacro(selectedGeneralMacro.generalText.replaceAll("<br>", " "))
          ).trim();
          const inputToReplace = inputText.replace(
            (macroSearchWithPeriod ? "." : breakChar) + macroPhrase,
            breakChar + textToReplaceWith + " "
          );
          const diff = textToReplaceWith.length - macroPhrase.length;
          let newIndex = index + diff;
          if (!macroSearchWithPeriod) {
            newIndex++;
          }
          setTimeout(() => el.setSelectionRange(newIndex, newIndex), 10);
          return inputToReplace;
        }
      }
    }
  }
  return inputText;
}

export async function batchRedirect() {
  if (Store.state.applicationSettings.warnIfNoDistribution) {
    const confirm = await window.confirm(
      "This case does not have automatic distribution set up. Would you like to go to Batch Distribution now?"
    );
    if (confirm) {
      window.open("/batch", "_blank");
    }
  }
}

export function isModalOpen() {
  const openModals = document.getElementsByClassName("modal-mask");
  return openModals?.length > 0;
}

export function getAltKeys(keys) {
  if (typeof keys === "string") {
    keys = keys.split("");
  }
  let obj = {};
  for (const key of keys) {
    obj[key] = altKey(key);
  }
  return obj;
}

export function getEditTypes() {
  return Object.keys(CaseEditTypeEnum).map(e => {
    return {
      id: CaseEditTypeEnum[e],
      displayName: e
    };
  });
}

export function getCaseProstateData(specimen) {
  return {
    prostateClinicalScore: specimen.prostateClinicalScore,
    caseGleasonScore: specimen.caseGleasonScore,
    likelihoodOfOrganConfinement: specimen.likelihoodOfOrganConfinement,
    riskOfExtraprostaticExtension: specimen.riskOfExtraprostaticExtension,
    extensionRiskOfSeminalVesicleInvolvement: specimen.extensionRiskOfSeminalVesicleInvolvement
  };
}

export function roundDecimals(number, places) {
  let numberText = number.toString();
  let matches = /\.\d+$/.exec(numberText);
  if (matches && typeof matches[0] === "string") {
    let decimals = matches[0].replace(/\D/, "");
    if (decimals.length > places) {
      decimals = decimals.slice(0, places);
      numberText = numberText.replace(matches[0], "." + decimals) + "...";
    }
  }
  return numberText;
}

export function getProstateSites(specimens, protocolId) {
  const sixMap = [3, 9, 15, 2, 8, 14];
  const twelveMap = [3, 4, 10, 9, 15, 16, 2, 1, 7, 8, 14, 13];
  const siteMap = JSON.parse(Store.state.labSettings.ProstateSiteMap);
  const mapToUse = specimens.length > 6 ? twelveMap : sixMap;
  for (const i in specimens) {
    const { specimenOrder } = specimens[i];
    const number = /\d/.test(specimenOrder)
      ? parseInt(specimenOrder) - 1
      : fromLetters(specimenOrder) - 1;
    if (number < mapToUse.length) {
      const siteIndex = mapToUse[number];
      specimens[i].site = siteMap[siteIndex];
    }
    if (protocolId) {
      specimens[i].protocolId = protocolId;
    }
  }
  return specimens;
}

export function clearSpellCheckHtml(text) {
  text = text
    .replaceAll(
      // Quill cursor messes with line breaks
      /<span class=\W*ql-cursor\W*>[^<]*<\/span>/gimu,
      ""
    )
    // Replaces non-breaking spaces
    .replaceAll(/&nbsp;"/gi, " ");
  const parser = new DOMParser();
  const parsed = parser.parseFromString(text, "text/html");
  const tags = parsed.querySelectorAll(".spell-checked-words");
  if (tags?.length) {
    for (const span of tags) {
      var pa = span.parentNode;
      while (span.firstChild) pa.insertBefore(span.firstChild, span);

      pa.removeChild(span);
    }
  }
  return parsed.body.innerHTML;
}
export function bitEnumToArray(value) {
  if (typeof value === "string") {
    value = parseInt(value);
  }
  if (!value) {
    return [];
  }
  let returnValue = [];
  const binary = value.toString(2);
  const reversed = binary.split("").reverse().join("");
  for (let i = 0; i < reversed.length; i++) {
    const bitValue = parseInt(reversed[i]);
    if (bitValue) {
      returnValue.push(Math.pow(2, i));
    }
  }
  return returnValue;
}

export function getBinMapFileDrop({
  protocol,
  prefix,
  priorityBinMapId,
  contactBinMapId,
  labBinMaps
}) {
  const binMapOrder = Store.state.labSettings.LabBinPrintOrder.split(",");
  function getTrayName(id) {
    const specimenBinMap = labBinMaps.find(e => e.id === id);
    if (specimenBinMap?.printers) {
      const binMapObject = specimenBinMap.printers.find(
        e => e.printerId === parseInt(process.env.VUE_APP_FILE_DROP_PRINTER_ID)
      );
      if (binMapObject?.bin) {
        return binMapObject.bin;
      }
    }
  }
  function checkBinMap(idx) {
    const item = binMapOrder[idx];
    switch (item) {
      case "protocol":
        if (protocol?.binMapId) {
          return getTrayName(protocol.binMapId);
        }
        break;
      case "prefix":
        if (prefix?.binMapId) {
          return getTrayName(prefix.binMapId);
        }
        break;
      case "accession":
        if (priorityBinMapId) {
          return getTrayName(priorityBinMapId);
        }
        break;
      case "provider":
        if (contactBinMapId) {
          return getTrayName(contactBinMapId);
        }
        break;
    }
    if (idx + 1 < binMapOrder.length) {
      return checkBinMap(idx + 1);
    }
    return "";
  }
  const trayName = checkBinMap(0);
  return trayName;
}

export {
  getTextFromHtml,
  getNextSpecimenOrder,
  collectedBeforeReceivedOn,
  zipCode,
  telephone,
  DateValidator,
  MaxDiffCollectedAndReceived,
  maxFutureDaysForReceived,
  SSNValidator,
  uuid,
  getKeyByValue,
  validatorMsgMapBase,
  parseTextInsideHTML,
  isNotEmpty,
  isIE,
  afterEffective,
  effectiveMinValue,
  noFutureDate
};

function rgbToHex(rgbString) {
  // Extract the RGB values
  const rgbValues = rgbString.match(/\d+/g);

  // Convert the RGB values to hexadecimal
  const hexValues = rgbValues.map(value => {
    const hex = Number(value).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  });

  // Combine the hex values
  const hexColor = "#" + hexValues.join("");

  return hexColor;
}
