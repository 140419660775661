import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import Auth from "../services/auth";
import api from "../services/api";
import {
  DropdownApi,
  MacrosApi,
  ProxyApi,
  QuickLinkAPI,
  TaskApi,
  UsersApi,
  SpellCheckApi
} from "../services/index";
import { dateRangeFilter } from "../modules/helpers";
import { cloneDeep, differenceBy } from "lodash";
import { accessionStore } from "./AccessionStore";
import { sessionDetails } from "./SessionDetails";
import { labSettings } from "./LabSettings";
import { defaultDropDownState, dropdowns } from "./Dropdowns";
import { grids } from "./Grids";
import { report } from "./Report";
import { applicationSettings } from "./ApplicationSettings";
export const SETTINGS_KEY = "IP_PRO_SETTINGS";
export const SESSION_KEY = "IP_PRO_SESSION_DETAILS";
export const FILES_KEY = "IP_PRO_FILES";
import localForage from "localforage";

import { getUserPermissions } from "@/modules/permissionsEnum";
import { syncMacroDb } from "@/modules/Quill/Macro";
import { MacroTypeEnum, PermissionsEnum } from "@/modules/enums";
import { defaultUserSettings } from "@/modules/defaultUserSettings";

export const IpProDb = localForage.createInstance({
  name: "IP_PRO",
  storeName: "IP_Pro_DB",
  description: "Storing all pdf & ip pro files for persistence."
});

Vue.use(Vuex);
const VuexLocalForage = new VuexPersist({
  key: SETTINGS_KEY,
  asyncStorage: true,
  saveState: async (key, state) => {
    return IpProDb.setItem(key, state);
  },
  restoreState: async key => {
    return await IpProDb.getItem(key);
  },
  reducer: ({
    report,
    applicationSettings,
    grids,
    labSettings,
    systemInformation,
    doctorProxies
  }) => ({
    report,
    applicationSettings,
    grids,
    labSettings,
    systemInformation,
    doctorProxies
  })
});

const VuexSessionStorage = new VuexPersist({
  key: SESSION_KEY,
  storage: window.sessionStorage,
  reducer: ({
    sessionDetails,
    currentUser,
    token,
    currentLab,
    currentLabLocation,
    availableLabs,
    availableLabLocations
  }) => ({
    sessionDetails,
    token,
    currentUser,
    currentLab,
    currentLabLocation,
    availableLabs,
    availableLabLocations
  })
});

const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const store = {
  state() {
    return {
      currentUser: {
        distinctPermissions: [],
        permissions: [],
        roles: []
      },
      updatePrompt: false,
      isMobileView: false,
      currentLab: null,
      currentLabLocation: null,
      availableLabs: [],
      availableLabLocations: [],
      token: null,
      userTz,
      systemInformation: {},
      userProcedures: [],
      lastPathologist: null,
      requisitionLabels: {
        isOpen: false
      },
      isPrintingModalOpen: false,
      caseStatuses: [
        "",
        "Acc",
        "Demo",
        "Spec",
        "Gross",
        "Orders",
        "ResultedOnHold",
        "SignedOnHold",
        "ReportedPrelim",
        "Resulted",
        "Signed",
        "Reported",
        "ResultedAgain",
        "SignedAgain",
        "ReReleased"
      ],
      doctorProxies: [],
      returnToQuickSignout: false,
      generalMacros: [],
      logTimeBase: 0
    };
  },
  modules: {
    accessionStore,
    sessionDetails,
    labSettings,
    dropdowns,
    report,
    applicationSettings,
    grids
  },
  getters: {
    mustSelectLab(state) {
      if (state.currentUser?.id && state.availableLabs.length) {
        const { userMustProvidePin, userMustChangePassword } = state.currentUser;
        if (userMustProvidePin || userMustChangePassword) {
          return false;
        }
        if (state.availableLabs.length !== 1 && !state.currentLab) {
          return true;
        }
      }
      return false;
    },
    permissions(state) {
      const distinctPermissions = state.currentUser?.distinctPermissions ?? [];
      return {
        ...getUserPermissions(distinctPermissions)
      };
    },
    isClientUser(state) {
      return state?.currentUser?.isLabClientUser || state?.currentUser?.userTypeId === 20;
    },
    webSpellcheckerLoaded() {
      return typeof window.WEBSPELLCHECKER !== "undefined";
    }
  },
  mutations: {
    RESTORE_SHARED_SESSION: VuexSessionStorage.RESTORE_MUTATION,
    clearDropdowns(state) {
      const defaultState = cloneDeep(defaultDropDownState);
      state.dropdowns = defaultState;
    },
    setMobileView(state, payload) {
      state.isMobileView = !!payload;
    },
    toggleUpdatePrompt(state) {
      state.updatePrompt = !state.updatePrompt;
    },
    logOut(state) {
      state.currentUser = { distinctPermissions: [] };
      state.currentLab = null;
      state.currentLabLocation = null;
      state.availableLabs = [];
      state.availableLabLocations = [];
      state.token = null;
      const defaultState = cloneDeep(defaultDropDownState);
      state.dropdowns = defaultState;
      state.applicationSettings = { ...state.applicationSettings, ...defaultUserSettings };
    },
    login(state, payload) {
      state.currentUser = { ...payload.user, distinctPermissions: [] };
    },
    setCurrentLab(state, payload) {
      state.currentLab = payload;
    },
    setAvailableLabs(state, payload) {
      state.availableLabs = payload;
    },
    setCurrentLabLocation(state, payload) {
      state.currentLabLocation = payload;
    },
    setAvailableLabLocations(state, payload) {
      state.availableLabLocations = payload;
    },
    setUserProcedures(state, payload) {
      state.userProcedures = payload;
    },

    toggleMacro(state) {
      state.macro.isDialogOpen = !state.macro.isDialogOpen;
    },
    setSystemInfo(state, payload) {
      state.systemInformation = payload;
    },
    setMacro(state, payload) {
      const { type, callback, target } = payload;
      state.macro.type = type;
      state.macro.callback = callback;
      state.macro.target = target;
    },
    clearMacro(state) {
      state.macro.type = null;
      state.macro.callback = null;
      state.macro.isDialogOpen = false;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    togglePrintingModal(state) {
      state.isPrintingModalOpen = !state.isPrintingModalOpen;
    },
    setUserRequiresPin(state, payload) {
      state.currentUser.userMustProvidePin = payload;
    },
    setUserMustChangePassword(state, payload) {
      state.currentUser.userMustChangePassword = payload;
    },
    setUserPermissions(state, payload) {
      state.currentUser.distinctPermissions = payload;
    },
    setDoctorProxies(state, payload) {
      state.doctorProxies = payload;
    },
    setReturnToQuickSignout(state, payload) {
      state.returnToQuickSignout = payload;
    },
    setGeneralMacros(state, payload) {
      state.generalMacros = payload;
    },
    setLogTimeBase(state) {
      state.logTimeBase = new Date().getTime();
      console.log("set log time base");
    },
    setCurrentUserSettings(state, payload) {
      if (typeof payload === "object") {
        payload = JSON.stringify(payload);
      }
      state.currentUser.settings = payload;
    }
  },
  actions: {
    async AUTH_LOGOUT({ commit, dispatch }) {
      sessionStorage.removeItem(SESSION_KEY);
      sessionStorage.removeItem("token");
      commit("logOut");
      dispatch("report/clearReport");
    },
    verifyPin({ commit }) {
      commit("setUserRequiresPin", false);
    },
    changedPassword({ commit }) {
      commit("setUserMustChangePassword", false);
    },
    async setAvailableLabs({ commit, dispatch, state }, payload) {
      commit("setAvailableLabs", payload);
      if (state.currentUser.userMustProvidePin || state.currentUser.userMustChangePassword) {
        return;
      }
      if (payload.length === 1) {
        await dispatch("setCurrentLab", payload[0].id);
      }
    },
    async setCurrentLab({ commit, dispatch, state }, payload) {
      commit("clearDropdowns");
      commit("setCurrentLab", payload);
      TaskApi.checkHighPriorityTasks();
      await dispatch("getUserPermissionsForLab");
      const labLocations = await DropdownApi.getLabLocations();
      commit("setAvailableLabLocations", labLocations);
      if (state.availableLabLocations.length === 1) {
        commit("setCurrentLabLocation", state.availableLabLocations[0].id);
      }
      if (state.availableLabLocations.length > 1) {
        const currentLabLocations = state.availableLabLocations.map(e => e.id);
        if (!currentLabLocations.includes(state.currentLabLocation)) {
          commit("setCurrentLabLocation", null);
        }
        const defaultLabLocationId = state.currentUser?.labLocationId;
        if (defaultLabLocationId) {
          commit("setCurrentLabLocation", defaultLabLocationId);
        } else if (state.currentUser.isPathologist) {
          commit("setCurrentLabLocation", null);
        }
      }
      dispatch("report/clearReport");
      if (state.currentUser.userTypeId !== 20) {
        dispatch("sessionDetails/getRoleQuickLinks", payload).catch(() =>
          console.log("Could not load quick links.")
        );
        if (state.currentUser.distinctPermissions.includes(PermissionsEnum.CaseSignoutByProxy)) {
          ProxyApi.getProxiesForUser().then(res => {
            commit("setDoctorProxies", res);
          });
        }
      }
      if (state.currentUser.userTypeId === 30) {
        DropdownApi.searchPathologists.load().then(res => {
          if (res.find(e => e.userId === state.currentUser.id)) {
            state.currentUser.isPathologist = true;
          } else {
            state.currentUser.isPathologist = false;
          }
        });
      }
      await dispatch("labSettings/getLabSettings", payload);
      await dispatch("syncMacroDb");
      if (state.currentUser.userTypeId > 20) {
        const userDictionary = await SpellCheckApi.getUserWordList(state.currentUser.id);
        if (!userDictionary) {
          SpellCheckApi.createUserDictionary([]);
        }
      }
    },
    async syncMacroDb() {
      await syncMacroDb();
    },
    async setCurrentLabLocation({ commit }, payload) {
      commit("setCurrentLabLocation", payload);
    },
    async setAvailableLabLocations({ commit }, payload) {
      commit("setAvailableLabLocations", payload);
    },
    async authenticate({ commit }, user) {
      const response = await Auth.login(user);
      commit("login", { user: response });
      if (window.newrelic && window.newrelic?.setCustomAttribute) {
        window.newrelic.setCustomAttribute("userId", user.id);
      }
      const { userMustProvidePin, userMustChangePassword, token } = response;

      commit("setToken", encodeURIComponent(token));
      if (userMustProvidePin || userMustChangePassword) {
        if (userMustProvidePin) {
          commit("setUserRequiresPin", true);
        }
        if (userMustChangePassword) {
          commit("setUserMustChangePassword", true);
        }
        return response;
      }
      if (response.labId) {
        commit("setCurrentLab", response.labId);
      }
      commit("sessionDetails/clearSessionDetails");
      // Moved to watcher in navbar
      // const currentUserSettings = JSON.parse(response.settings || "{}");
      // commit("applicationSettings/setAppSettings", currentUserSettings);
      return response;
    },
    async getUserProcedures({ commit, state }, payload) {
      const { labId } = payload;
      const procedureStore = api.createSearch(
        `/api/Labs/${labId}/Procedures`,
        "id",
        undefined,
        loadOptions => {
          if (Array.isArray(loadOptions.filter)) {
            loadOptions.filter = [
              ...loadOptions.filter,
              ["and", dateRangeFilter("effectiveOn", "expiresOn")]
            ];
          } else {
            loadOptions.filter = [dateRangeFilter("effectiveOn", "expiresOn")];
          }
        }
      );
      let links = await QuickLinkAPI.quickLinksByRole();
      const diffQuickLinks = differenceBy(
        state.userProcedures.map(e => e?.id || e.macroId),
        links.map(e => [1, 2].includes(e.linkType) && e.id).filter(e => e)
      );
      if (diffQuickLinks.length || !state.userProcedures.length) {
        const calls = links.map(link => {
          if (link.linkType === 1) {
            return procedureStore.byKey(link?.id);
          } else if (link.linkType === 2) {
            return MacrosApi.searchStore.byKey(link?.id);
          }
        });
        const quickLinks = (await Promise.all(calls)).filter(e => e?.id || e?.macroId);
        commit("setUserProcedures", quickLinks);
      } else {
        return true;
      }
      return true;
    },
    async getUserGeneralMacros({ commit, state }) {
      if (!state.generalMacros?.length) {
        MacrosApi.getMacrosByUserAndType({
          userId: state.currentUser.id,
          macroTypeId: MacroTypeEnum.General,
          loadOptions: {}
        }).then(res => {
          commit("setGeneralMacros", res.data || []);
        });
      }
    },
    logTimeSinceBase({ state }, message) {
      console.log(new Date().getTime() - state.logTimeBase, message);
    },
    async getUserPermissionsForLab({ commit }) {
      const userPermissions = await UsersApi.getUserPermissions();
      commit("setUserPermissions", userPermissions);
    }
  },
  plugins: [VuexLocalForage.plugin, VuexSessionStorage.plugin]
};
export default new Vuex.Store(store);
