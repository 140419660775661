import { debounceTime, filter, map, tap } from "rxjs/operators";
import { mapState } from "vuex";
import { capitalize, clearSpellCheckHtml, scrollToElement } from "@/modules/helpers";
import { SpellCheckApi } from "@/services";
import { uniqBy } from "lodash";

export default {
  data() {
    return {
      checkedWords: [],
      problemWords: [],
      ignoredWords: [],
      selectedWordToCheck: "",
      selectedElement: null,
      lastSpellCheckText: "",
      lastSpellCheckTime: 0,
      spellCheckBoxPosition: null,
      isOnSave: false,
      spellCheckElements: [],
      currentSpellCheckIndex: 0
    };
  },
  subscriptions() {
    const valueChanged$ = this.$watchAsObservable("value", { immediate: true }).pipe(
      filter(({ newValue }) => {
        return newValue;
      }),
      map(() => this.component?.isMentionsOpen),
      filter(newValue => {
        return !newValue;
      }),
      debounceTime(500),
      tap(() => {
        this.runSpellCheck();
      })
    );
    return { valueChanged$ };
  },
  watch: {
    selectedWordToCheck: {
      handler(nv) {
        clearInterval(this.intervalId);
        if (nv) {
          this.intervalId = setInterval(() => {
            this.placeSpellCheckBox(this.selectedElement);
          }, 500);
        }
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    async runSpellCheck() {
      if (!this.enableSpellchecker || this.selectedWordToCheck || !this.value) {
        return;
      }
      const text = this.value.replaceAll(/<[^>]+>/gi, " ");
      if (!text?.length) {
        this.lastSpellCheckText = "";
        return;
      }
      this.lastSpellCheckTime = new Date().getTime();
      if (text === this.lastSpellCheckText) {
        return;
      }
      this.lastSpellCheckText = text;
      const wordList = uniqBy(
        text
          .split(/[^\w'-]+/g)
          .filter(e => e && /[a-z]/i.test(e) && !/^(\d+)?(x)?\d+([cm]m)?$/i.test(e))
          .map(e => e.toLowerCase())
      );
      this.checkedWords = wordList;
      const spellCheck = await SpellCheckApi.runSpellCheck(wordList);
      this.problemWords = spellCheck.filter(e => !e.correct);
      this.highlightAllProblemWords();
      return;
    },
    highlightAllProblemWords() {
      const editorEl = this.getEditorEl();
      const editorText = clearSpellCheckHtml(editorEl.innerHTML);
      let textToReplace = editorText;
      for (const { word } of this.problemWords) {
        if (!this.ignoredWords.includes(word.toLowerCase())) {
          textToReplace = this.highlightText(word, textToReplace);
        }
      }
      this.updateInnerHtml(textToReplace);
    },
    highlightText(word, text) {
      // Regex negative lookahead prevents words within HTML tags from being replaced
      function getRegex(innerWord) {
        return new RegExp("(?<!\\w)" + innerWord + "(?!([^<]*>)|(\\w))", "ig");
      }
      const regex = getRegex(word);
      const matches = uniqBy([...text.matchAll(regex)].map(e => e[0]));
      for (const match of matches) {
        text = text.replaceAll(
          getRegex(match),
          `<span class='spell-checked-words'>${match}</span>`
        );
      }
      return text;
    },
    async handleSelectWord(event) {
      event.preventDefault();
      const { target } = event;
      const editorWrapper = this.$refs.editor;
      const editorComponent = editorWrapper?.component;
      const index = editorComponent?.getSelection()?.index || 0;
      const editorTextContent = editorComponent?.getText();
      let newSelection = { start: index, end: index };
      for (let i = index; i < editorTextContent.length; i++) {
        const letter = editorTextContent[i];
        if (/[^\w'-]/.test(letter)) {
          newSelection.end = i;
          break;
        }
        if (letter === undefined) {
          break;
        }
      }
      for (let i = index; i > -1; i--) {
        if (i === 0) {
          newSelection.start = 0;
          break;
        }
        const letter = editorTextContent[i];
        if (/[^\w'-]/.test(letter)) {
          newSelection.start = i + 1;
          break;
        }
        if (letter === undefined) {
          break;
        }
      }
      editorComponent.setSelection(newSelection.start, newSelection.end - newSelection.start);
      this.selectedElement = target;
      this.selectedWordToCheck = this.isForcedUpperCase
        ? target.textContent.toUpperCase()
        : target.textContent;
      this.isSpellCheckBoxOpen = true;
      this.$nextTick(() => {
        this.placeSpellCheckBox(target);
      });
    },
    closeSpellCheck() {
      this.selectedWordToCheck = "";
    },
    ignoreWord(word) {
      if (!this.ignoredWords.includes(word.toLowerCase())) {
        this.ignoredWords.push(word.toLowerCase());
        this.highlightAllProblemWords();
        this.selectNextWord();
      }
    },
    handleReplaceWord(word) {
      const delta = word.length - this.selectedElement.innerHTML.length;
      this.selectedElement.outerHTML = word;
      this.selectNextWord(delta);
    },
    handleAddWord(word) {
      this.problemWords = this.problemWords.filter(
        e => e.word.toLowerCase() !== word.toLowerCase()
      );
      this.highlightAllProblemWords();
      this.selectNextWord();
    },
    updateInnerHtml(text) {
      const editorWrapper = this.$refs.editor;
      const editorComponent = editorWrapper?.component;
      const selection = editorComponent?.getSelection();
      const editorRef = editorWrapper.$el;
      const editorEl = this.getEditorEl();
      editorEl.innerHTML = text;
      this.$nextTick(() => {
        const elements = editorRef.getElementsByClassName("spell-checked-words");
        for (const element of elements) {
          element.style.textDecoration = "underline wavy red";
          element.addEventListener("contextmenu", this.handleSelectWord);
        }
        if (editorWrapper?.isFocused && selection?.index) {
          editorComponent.setSelection(selection.index, 0);
        }
      });
    },
    async runSpellCheckOnSave() {
      if (!this.value) {
        this.$emit("spellCheckDone");
        return;
      }
      const editorRef = this.$refs.editor.$el;
      this.spellCheckElements = editorRef.getElementsByClassName("spell-checked-words");
      if (!this.spellCheckElements.length) {
        this.$emit("spellCheckDone");
        return;
      }
      const diff = scrollToElement(this.$refs.editor.$el);
      setTimeout(() => {
        this.isOnSave = true;
        this.spellCheckElements = editorRef.getElementsByClassName("spell-checked-words");
        this.currentSpellCheckIndex = 0;
        this.selectNextWord();
      }, diff * 0.75);
    },
    selectNextWord(delta = 0) {
      if (!this.isOnSave) {
        this.selectedWordToCheck = "";
        const editorWrapper = this.$refs.editor;
        const { index, length } = editorWrapper?.lastSelection;
        const editorComponent = editorWrapper?.component;
        this.$nextTick(() => {
          editorWrapper.focus(true, true);
          const newIndex = index + length + delta;
          editorComponent.setSelection(newIndex, 0);
        });
        return;
      }
      if (!this.spellCheckElements.length) {
        this.selectedWordToCheck = "";
        this.$emit("spellCheckDone");
        return;
      }
      this.handleSelectWord({
        target: this.spellCheckElements[0],
        preventDefault: () => {
          return;
        }
      });
      this.currentSpellCheckIndex++;
    },
    placeSpellCheckBox(element) {
      let { top, right, bottom } = element.getBoundingClientRect();
      const main = document.getElementsByTagName("main");
      const mainRect = main[0].getBoundingClientRect();
      const el = this.$refs.spellCheckBox;
      if (mainRect.top > top) {
        top = mainRect.top;
      } else if (mainRect.bottom < bottom) {
        const boxRect = el.getBoundingClientRect();
        const boxHeight = boxRect.bottom - boxRect.top;
        top = mainRect.bottom - boxHeight;
      }
      el.style.top = top + "px";
      el.style.left = right + "px";
    },
    getEditorEl() {
      const editorRef = this.$refs.editor.$el;
      const className = this.useDragonEditors ? "editor" : "dx-htmleditor-content";
      const items = editorRef.getElementsByClassName(className);
      if (items?.length) {
        return items[0];
      }
    },
    handleReplaceAll({ original, replacement }) {
      const editorRef = this.$refs.editor.$el;
      const spellCheckElements = [...editorRef.getElementsByClassName("spell-checked-words")];
      for (const element of spellCheckElements) {
        if (element.innerHTML.toLowerCase().replaceAll(/<[^>]*>/g, "") === original.toLowerCase()) {
          element.outerHTML = replacement;
        }
      }
      this.selectNextWord();
    },
    setSpellCheckBoxPosition({ deltaX, deltaY }) {
      clearInterval(this.intervalId);
      const el = this.$refs.spellCheckBox;
      const { left, top } = el.getBoundingClientRect();
      const newLeft = left + deltaX;
      const newTop = top + deltaY;
      el.style.top = newTop + "px";
      el.style.left = newLeft + "px";
    }
  },
  computed: {
    ...mapState({
      applicationSettings: state => state.applicationSettings,
      specimens: state => state.accessionStore.specimens,
      MacroSearchWithPeriod: state => state.labSettings.MacroSearchWithPeriod,
      currentSpecimen: state => state.accessionStore.currentSpecimen,
      SpellCheckOnSave: state => state.labSettings.SpellCheckOnSave,
      useDragonEditors: state => state.applicationSettings.useDragonEditors,
      labSettings: state => state.labSettings,
      enableSpellchecker: state => state.applicationSettings.enableSpellchecker
    }),
    wordToCheck() {
      if (this.selectedWordToCheck) {
        const wordData = this.problemWords.find(
          e => e.word.toLowerCase() === this.selectedWordToCheck.toLowerCase()
        );
        return { ...wordData, word: this.selectedWordToCheck };
      }
      return null;
    },
    isForcedUpperCase() {
      let settingName = "";
      switch (this.name) {
        case "notes":
          settingName = "SpecimenNote";
          break;
        case "caseNotes":
          settingName = "CaseNote";
          break;
        default:
          settingName = capitalize(this.name);
      }
      if (settingName) {
        return Boolean(this.labSettings["ForceUpperCase" + settingName]);
      } else {
        return false;
      }
    }
  }
};
