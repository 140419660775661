<template>
  <Container class="p-2">
    <DxGridWithSearch
      ref="reportsGrid"
      title="Reports"
      gridName="reports"
      @initialized="initGrid"
      :dataSource="reportsStore"
      :columns="columns"
      :noDataText="'No reports found.'"
      :toolbar="toolbar"
      :selection="selection"
    >
      <template v-slot:actions="{ data }">
        <div>
          <icon-button
            type="button"
            v-tooltip.left="data.data.uniqueKey ? 'View report' : 'No report found'"
            class="text-primary mx-1 btn p-0"
            @click="viewReport(data)"
            :icon="data.data.uniqueKey ? 'eye' : 'eye-slash'"
            v-if="permissions.DailyReportView"
          />
          <icon-button
            type="button"
            v-if="showEditButton"
            class="text-primary mx-1 btn p-0"
            v-tooltip.left="'Edit Report'"
            @click="handleEdit(data)"
            icon="pen-alt"
          />
          <icon-button
            type="button"
            class="text-danger mx-1 btn p-0"
            v-tooltip.left="'Delete Report'"
            @click="handleDelete(data)"
            v-if="permissions.ReportCreateEdit"
            icon="trash-alt"
          />
        </div>
      </template>
      <template v-slot:syncLoader>
        <loader size="small" />
      </template>
    </DxGridWithSearch>
    <div class="" v-if="isEditing">
      <ReportForm @close="handleClose" v-model="currentReport" />
    </div>

    <daily-report-viewer
      v-if="isViewing && currentReport"
      :reportId="currentReport.id"
      :reportName="currentReport.name"
      @close="handleCloseReport"
    />
  </Container>
</template>

<script>
import ReportService from "@/services/Reports";
import eventBus, { CHANGE_GROUP } from "@/modules/eventBus";
import DxGridWithSearch from "@/components/common/DxGridWithSearch";
import ReportForm from "@/components/forms/ReportForm";
import { mapGetters, mapState } from "vuex";
import { AuditLogApi, ReportsApi } from "@/services";

import { createLogItem, filterCellUTC, formatDatetimeCell } from "@/modules/helpers";
import Container from "@/components/common/Container.vue";
import IconButton from "@/components/common/IconButton.vue";
import DailyReportViewer from "./Reports/DailyReportViewer.vue";
import Loader from "./common/Loader.vue";
export default {
  components: {
    DxGridWithSearch,
    ReportForm,
    Container,
    IconButton,
    DailyReportViewer,
    Loader
  },
  props: ["selectedGroup"],
  name: "ReportsGrid",
  data() {
    return {
      selection: {
        enabled: false
      },
      view: "pdf",
      reportsStore: [],
      reportProps: null,
      group: null,
      reportUrl: null,
      isViewing: false,
      isEditing: false,
      grid: {},
      labs: [],
      isLoading: false,
      mode: null,
      currentReport: null,
      parameters: {}
    };
  },
  computed: {
    ...mapState(["currentUser", "reportViewer", "currentLab", "isMobileView"]),
    ...mapState({
      groups: state => state.dropdowns.reportGroups
    }),
    ...mapGetters("report", ["supportsPDF"]),
    ...mapGetters(["permissions"]),
    toolbar() {
      return {
        items: [
          {
            template: "syncLoader",
            visible: this.isLoading
          },
          {
            widget: "dxButton",
            visible: this.permissions.ReportCreateEdit,
            options: {
              icon: "refresh",
              onClick: this.refreshReportService,
              hint: "Click to refresh reports."
            }
          }
        ]
      };
    },
    columns() {
      return [
        {
          dataField: "name",
          dataType: "string",
          sortIndex: 0
        },
        {
          dataField: "description",
          dataType: "string"
        },
        {
          dataField: "lastRunOn",
          dataType: "datetime",
          calculateCellValue(data) {
            if (data.lastRunOn) {
              return formatDatetimeCell(data.lastRunOn);
            }
            return "";
          },
          calculateFilterExpression: filterCellUTC("lastRunOn")
        },
        {
          dataField: "lastRunBy",
          dataType: "string"
        },
        {
          dataField: "Actions",
          type: "buttons",
          cellTemplate: "actions"
        },
        {
          dataField: "reportGroupId",
          caption: "Group",
          type: "number",
          lookup: {
            valueExpr: "id",
            displayExpr: "displayName",
            dataSource: { key: "id", store: this.groups }
          }
        }
      ];
    },
    reportParams() {
      if (this.currentReport?.id && this.currentReport.reportParams) {
        return JSON.parse(this.currentReport.reportParams)?.filter(
          param => param.ParameterVisibility === "Visible" && !/labId/i.test(param.Name)
        );
      }
      return [];
    },
    showEditButton() {
      return (
        this.permissions.ReportCreateEdit ||
        this.permissions.ReportDescriptionEdit ||
        this.permissions.ReportGroupEdit
      );
    }
  },
  created() {
    eventBus.$on(CHANGE_GROUP, group => {
      this.group = group;
    });
    this.$store.dispatch("dropdowns/getReportGroups");
    ReportsApi.getDailyReports(this.currentLab).then(reports => {
      this.reportsStore = reports || [];
    });
  },
  beforeDestroy() {
    eventBus.$off(CHANGE_GROUP);
  },
  watch: {
    selectedGroup(nv) {
      this.handleGroupFilter(nv);
    }
  },
  methods: {
    initGrid({ component }) {
      this.grid = component;
      this.handleGroupFilter(null);
    },
    handleCloseReport() {
      this.reportProps = null;
      this.currentReport = null;
    },
    handleGroupFilter(groupId) {
      this.grid.columnOption("reportGroupId", "filterValue", groupId);
    },
    async refreshReportService() {
      try {
        this.isLoading = true;
        const response = await ReportService.SSRSSYNC();
        if (response?.deletedReportsStillUsed?.length) {
          window.alert(`
          <h6>The following entities no longer have a valid path report.</h6>
          <ol>
          ${response.deletedReportsStillUsed.map(item => `<li>${item}</li>`).join("")}
          </ol>
          `);
        }
        this.reportsStore = await ReportsApi.getDailyReports(this.currentLab);
      } catch (error) {
        window.notify("Error occurred", "error");
      } finally {
        this.isLoading = false;
      }
    },
    async viewReport({ data }) {
      this.isViewing = true;
      this.isEditing = false;
      this.reportUrl = null;
      this.currentReport = data;
    },
    async handleEdit({ data }) {
      if (data.id != this.currentReport?.id && (this.isViewing || this.isEditing)) {
        const confirm = await window.confirm(
          "You may have unsaved data.<br> Do you wish to continue?"
        );
        if (!confirm) {
          return;
        }
      }
      this.currentReport = data;
      this.reportUrl = null;
      this.isEditing = true;
      this.isViewing = false;
    },
    async handleDelete({ data }) {
      this.isViewing = false;
      this.currentReport = null;
      this.reportUrl = null;
      this.isEditing = false;
      const confirm = await window.confirm(
        "WARNING: This action is irreversible.<br> Do you wish to continue?"
      );
      if (!confirm) {
        return;
      }
      await ReportService.deleteReport(data);
      ReportsApi.getDailyReports(this.currentLab).then(reports => {
        this.reportsStore = reports || [];
      });
    },
    handleClose() {
      this.isViewing = false;
      this.currentReport = null;
      this.reportUrl = null;
      this.isEditing = false;
      ReportsApi.getDailyReports(this.currentLab).then(reports => {
        this.reportsStore = reports || [];
      });
    },
    submitReportParameters(data) {
      this.reportProps = data;
    },
    handlePdfDownload() {
      AuditLogApi.insertLogMessage({
        ...createLogItem({}, 16),
        comments: `Downloaded ${this.currentReport.name} report pdf`
      });
    },
    handlePrint() {
      AuditLogApi.insertLogMessage({
        ...createLogItem({}, 16),
        comments: `Printed ${this.currentReport.name} report pdf`
      });
    }
  }
};
</script>
<style lang="scss" scoped></style>
